<nav class="site-header" (scroll)="onscroll()" [ngClass]="navbarColor ? 'color' : 'noColor'">
    <div class="content">
        <ng-container *ngIf="!showBackArrow; else backArrow">
            <div class="mobile-menu">
                <div class="linkTrigger" #linksMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="linksMenu">
                    <img class="linkTrigger__image" src="assets/images/all-pages.svg" alt="logo">
                </div>
                <mat-menu #linksMenu="matMenu">
                    <ng-container *ngFor="let link of headerLinks">
                        <div class="text-dropdown" (click)="scrollTo(link.linkAction)">
                            {{ "header." + link.context | translate }}
                        </div>
                    </ng-container>
                </mat-menu>
            </div>
        </ng-container>

        <ng-template #backArrow>
            <a class="linkTrigger back-btn" (click)="goBack()">
                <img src="assets/images/back-arrow.svg" alt="back">
            </a>
        </ng-template>

        <div class="logo-container" routerLink="">
            <img class="logo" src="assets/images/Logo.svg" alt="logo">
            <img class="logo-text" src="assets/images/LogoText.svg" alt="logo">
        </div>

        <div class="pages">
            <ng-container *ngFor="let link of headerLinks">
                <a class="text page-link" [attr.id]="link.linkId" (click)="linkAction(link.linkAction)">
                    {{ "header." + link.context | translate }}
                </a>
            </ng-container>
        </div>

        <div class="languages-block">
            <mat-button-toggle-group [value]="currentLang" (change)="changeLanguage($event.value)">
                <mat-button-toggle *ngFor="let lang of languages" disableRipple [value]="lang">
                    {{ lang }}
                </mat-button-toggle>
            </mat-button-toggle-group>

            <div class="trigger" #languageMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="langsMenu"></div>

            <mat-menu #langsMenu="matMenu">
                <ng-container *ngFor="let lang of languages">
                    <div class="text-dropdown lang" (click)="changeLanguage(lang)">{{ lang }}</div>
                </ng-container>
            </mat-menu>
        </div>
    </div>
</nav>
