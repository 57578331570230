<footer class="wrapper">
    <div class="content-w">
        <div class="left-block">
            <div class="logo-block">
                <img class="logo" src="assets/images/Logo.svg" alt="logo">
                <img class="logo-text" src="assets/images/LogoText.svg" alt="logo">
            </div>
        </div>

        <div class="center-block">
            <p class="_title-small" >
                <a class="address" href="https://goo.gl/maps/kz4KU88xq824mYge6" target="_blank" rel="noopener">
                    {{ "footer.address" | translate }}
                </a>
            </p>
        </div>
        <div class="center-block ml-10">
            <p class="_title-small">
                {{ "footer.phone" | translate }}: <a href="tel:+380973535450">+38(097) 35-35-450</a>
            </p>
            <p class="_title-small">
                Email: <a href="mailto:">rizdvo@ukr.net</a>
            </p>
        </div>

        <div class="right-block">
            <!-- <p class="_title-small text">{{ "footer.followUs" | translate }}</p> -->
            <div class="social">
                <!-- <a href="https://www.instagram.com/rizdvakhrystovogo/?hl=uk" target="_blank" rel="noopener">
                    <img src="assets/images/instagram-link.svg" alt="instagram">
                </a> -->
                <a href="https://www.facebook.com/groups/992813437404592" target="_blank" rel="noopener">
                    <img src="assets/images/facebook-link.svg" alt="facebook">
                </a>
                <a href="https://www.youtube.com/c/%D0%A6%D0%B5%D1%80%D0%BA%D0%B2%D0%B0%D0%A0%D1%96%D0%B7%D0%B4%D0%B2%D0%B0%D0%A5%D1%80%D0%B8%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE"
                    target="_blank" rel="noopener">
                    <img src="assets/images/Youtube.svg" alt="youtube">
                </a>
            </div>

        </div>
    </div>
</footer>
