<div mat-dialog-title>
    <h3 class="title">{{ "mainPage.broadcasts.modalWindowTitle" | translate }}</h3>
    <button mat-button mat-dialog-close>&#10006;</button>
</div>

<div mat-dialog-content *ngIf="videoUrl">
    <div class="video-wrapper">
        <iframe 
            [src]="videoUrl"
            frameborder="0" 
            allowfullscreen
        ></iframe>
    </div>
</div>

<div mat-dialog-action class="mat-dialog-action">
    <h3 class="_title-small" [innerHTML]="title"></h3>
</div>

