<div mat-dialog-title>
    <h3 class="tc">{{ "donate.title" | translate }}</h3>
    <button mat-button mat-dialog-close>&#10006;</button>
</div>
<div mat-dialog-content>
    <mat-tab-group mat-stretch-tabs [disablePagination]="true">
        <mat-tab>
            <ng-template mat-tab-label>
                {{"donate." + (isAbroad ? "abroadTab" : "ukraineTab") | translate}}
            </ng-template>
            <ng-container *ngTemplateOutlet="isAbroad ? abroad : Ukraine"></ng-container>
        </mat-tab>
        
        <mat-tab>
            <ng-template mat-tab-label>
                {{"donate." + (isAbroad ? "ukraineTab" : "abroadTab") | translate}}
            </ng-template>
            <ng-container *ngTemplateOutlet="isAbroad ? Ukraine : abroad"></ng-container>
        </mat-tab>
    </mat-tab-group>
</div>


<ng-template #Ukraine>
    <div class="tab-container">
        <a class="row" [href]="privatUrl" target="_blank">
            <img class="logo" src="assets/images/privat24.png">
            <div class="_text-medium text">{{"donate.private24" | translate}}</div>
        </a>

        <div class="details">
            <div class="detail-block">
                <div class="detail-label">{{"donate.receiver" | translate}}:</div>
                <div class="detail-value">{{ organization }}</div>
            </div>
            <div class="detail-block">
                <div class="detail-label">{{"donate.currentAccount" | translate}}:</div>
                <div class="detail-value">{{ IBAN }}</div>
            </div>
            <div class="detail-block">
                <div class="detail-label">{{"donate.USREOU" | translate}}:</div>
                <div class="detail-value">{{ USREOU }}</div>
            </div>
            <div class="detail-block">
                <div class="detail-label">{{"donate.purpose" | translate}}:</div>
                <div class="detail-value">{{"donate.purposeText" | translate}}</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #abroad>
    <div class="tab-container">
        <a class="row" target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=KMGLJJUZQAT82">
            <img class="logo" src="assets/images/uplift-logo.jpg">
            <div class="_text-medium text">{{"donate.uplift" | translate}}</div>
        </a>
        <a class="row" target="_blank" href="https://givebutter.com/drq3ML">
            <img class="logo" src="assets/images/uplift-logo.jpg">
            <div class="_text-medium text">{{"donate.givebutter" | translate}}</div>
        </a>
        <a class="row" href="assets/pdf/details-for-payments-in-dollars.pdf" target="_blank" download="details-for-payments-in-dollars.pdf">
            <img class="pdf-logo" src="assets/images/pdf-logo.png">
            <div class="_text-medium text">{{"donate.dollarDetailsPdf" | translate}}</div>
        </a>
        <a class="row" href="assets/pdf/details-for-payments-in-euros.pdf" target="_blank" download="details-for-payments-in-euros.pdf">
            <img class="pdf-logo" src="assets/images/pdf-logo.png">
            <div class="_text-medium text">{{"donate.euroDetailsPdf" | translate}}</div>
        </a>
    </div>
</ng-template>